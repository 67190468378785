import React from "react";
import {
  Popover,
  Menu,
  Dialog,
  Button,
  FormGroup,
  Classes,
  InputGroup,
  MenuItem,
  MenuDivider,
} from "@blueprintjs/core";
// import { SectionTab } from "polotno/side-panel";

import { useProject } from "../project";
import { FileMenu } from "../topbar/file-menu";
import { parsePDF, parseSlides } from "../api";
import { feedbackIntegration } from "@/instrument";

const SLIDES_URL_PATTERN =
  "https:\\/\\/docs.google.com\\/presentation\\/d\\/([a-zA-Z0-9_\\-]+)(|\\/.*)";
const SLIDES_URL_REGEX = new RegExp(SLIDES_URL_PATTERN);

export const MainSection = {
  name: "main",
  Tab: (props) => {
    const [slidesLoading, setSlidesLoading] = React.useState(false);
    const [slidesOpened, setSlidesOpened] = React.useState(false);

    const project = useProject();
    const { store } = project;

    const pdfInputRef = React.useRef<HTMLInputElement>(null);

    // <SectionTab
    //   onClick={(e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //   }}
    //   {...props}
    // >
    return (
      <>
        <input
          type="file"
          accept=".pdf"
          ref={pdfInputRef}
          style={{ width: "180px", display: "none" }}
          onChange={async (e) => {
            var input = e.target as HTMLInputElement;

            if (!input.files?.length) {
              return;
            }

            const file = input.files[0];

            project.runWithLoading(async () => {
              const json = await parsePDF({ file });

              await project.createNewDesign();
              store.loadJSON(json);
              await project.save();
              input.value = "";
            }, "Failed to parse PDF, please try again later.");
          }}
        />
        <input
          type="file"
          id="load-project"
          accept=".json,.polotno"
          style={{ width: "180px", display: "none" }}
          onChange={(e) => {
            var input = e.target;

            if (!input.files.length) {
              return;
            }

            var reader = new FileReader();
            reader.onloadend = async function () {
              var text = reader.result;
              console.log(JSON.parse(text));
              let json;
              try {
                json = JSON.parse(text);
              } catch (e) {
                alert("Can not load the project.");
              }

              if (json) {
                project.runWithLoading(async () => {
                  await project.createNewDesign();
                  store.loadJSON(json);
                  project.save();
                  input.value = "";
                });
              }
            };
            reader.onerror = function () {
              alert("Can not load the project.");
            };
            reader.readAsText(input.files[0]);
          }}
        />
        <Popover
          fill
          placement="right-end"
          interactionKind="hover"
          enforceFocus={false}
          targetProps={{ style: { cursor: "pointer" } }}
          hoverOpenDelay={0}
          content={
            <Menu>
              <FileMenu
                store={store}
                project={project}
                pdfInputRef={pdfInputRef}
              />

              {!project.basicMode && (
                <MenuItem
                    // icon={<FaFileImport />}
                icon="import"
                text="Import Google Slides"
                onClick={(e) => {
                  if (project.demoMode) {
                    project.openDemoDialog();
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  if (!project.cloudEnabled) {
                    project.openLoginDialog();
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  setSlidesOpened(true);
                }}
              />
              )}
              <MenuDivider />
              <ReportIssueMenuItem />
              <MenuDivider />
              <MenuItem
                icon="info-sign"
                text="Terms of Service"
                href={import.meta.env.VITE_PUBLIC_TERMS_OF_SERVICE_URL}
              />
            </Menu>
          }
        >
          <svg
            style={{
              padding: "0",
              margin: "0.25rem",
              borderRadius: "1rem",
            }}
            viewBox="0 0 1859 1859"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="TeachShare Settings"
          >
            <path d="M0 0H1859V1859H0V0Z" fill="#6271EB"/>
            <path d="M841.926 1087C826.187 1087 813.427 1074.24 813.427 1058.5V442.983C813.427 427.244 826.186 414.485 841.926 414.485H1017.07C1032.81 414.485 1045.57 427.244 1045.57 442.983V1058.5C1045.57 1074.24 1032.81 1087 1017.07 1087H841.926ZM560.498 607.738C544.759 607.738 532 594.979 532 579.239V436.498C532 420.759 544.759 408 560.498 408H1298.5C1314.24 408 1327 420.759 1327 436.498V579.239C1327 594.979 1314.24 607.738 1298.5 607.738H560.498Z" fill="white"/>
            <path d="M1111.96 1284.8C1089.91 1244.44 1045.81 1134.77 1045.81 1018.94C968.348 1019.35 813.42 1019.92 813.409 1018.94C814.578 1109.77 771.129 1234.68 750.341 1284.8C867.772 1362.49 890.283 1402.72 911.01 1545.67H947.207C972.164 1396.76 996.48 1356.57 1111.96 1284.8Z" fill="white"/>
            <circle cx="930.986" cy="1232.38" r="40.9365" fill="#6271EB"/>
            <rect x="912.365" y="1259.75" width="35.4874" height="285.918" fill="#6271EB"/>
          </svg>
        </Popover>
        <Dialog
          icon="import"
          onClose={() => setSlidesOpened(false)}
          title="Import from Google Slides"
          isOpen={slidesOpened}
          style={{
            width: "80%",
            maxWidth: "700px",
            maxHeight: "100vh",
            overflow: "auto",
          }}
        >
          <div className={Classes.DIALOG_BODY}>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const form = e.target as HTMLFormElement;
                const formData = new FormData(form);
                const urlInput = formData.get(
                  "google-slides-url-input"
                ) as string;
                const id = urlInput.match(SLIDES_URL_REGEX)?.[1];
                if (!id) {
                  alert("Invalid Google Slides URL!");
                  return;
                }

                setSlidesLoading(true);

                const json = await parseSlides({ id });

                try {
                  await project.createNewDesign();
                  store.loadJSON(json);
                  await project.save();
                } finally {
                  setSlidesLoading(false);
                }
              }}
            >
              <FormGroup
                helperText="Make sure the Google Slides is shared with anyone who has the link!"
                label="Google  Slides URL"
                labelFor="google-slides-url-input"
              >
                <InputGroup
                  id="google-slides-url-input"
                  name="google-slides-url-input"
                  required
                  placeholder="https://docs.google.com/presentation/d/1ACYbIKidDaSJBm7qbqCyjR8jIQMpl_fA6pOpvzNJEkE/"
                  pattern={SLIDES_URL_PATTERN}
                />
              </FormGroup>
              <Button type="submit" intent="primary" loading={slidesLoading}>
                Import
              </Button>
            </form>
          </div>
        </Dialog>
      </>
    );
    // </SectionTab>
  },
  Panel: (props) => undefined,
};

const ReportIssueMenuItem = () => (
  <MenuItem
    icon="error"
    text="Report an Issue"
    onClick={() => {
      feedbackIntegration.createForm().then((form) => {
        form.appendToDom();
        form.open();
      })
    }}
  />
)
